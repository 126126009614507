<template>
	<div class="EnergyPredictionComponent">
		<energyPrediction :searchBoxCond="searchBoxCondType2"></energyPrediction>
	</div>
</template>

<script>
	import energyPrediction from "./EnergyPrediction.vue";

	export default {
		name: "EnergyPredictionComponent",
		components: {
			energyPrediction,
		},
		data() {
			let now = new Date();

			return {
				searchBoxCondOld: {
					isChecked: true,
					conditions: {
						searchBoxChecked: false,
						categoryType: "Conspt",
						analType: "Hour",

						compareType: "none",
						period: {
							fromDate: new Date(),
							toDate: "",
							compFromDate: "",
							compToDate: "",
							disabled: true,
						},
						searchSystemGroupCode: {
							codes: [],
							selected: "",
						},
						searchSystemCode: {
							codes: [],
							selected: "",
						},
						chargeCode: {
							codes: [],
							selected: "",
						},
						chargeBasisCode: {
							codes: [],
							selected: "",
						},
					},
				},

				// 표준 조회 조건 (15분,시간대별,일별,요일별,월별,연도별)
				searchBoxCond: {
					viewType: this.type,
					conditions: {
						timeDsvn: "Month",
						compareType: "none",
					},
					datePicker: {
						viewMode: "days",
						paramFromFormatter: "yyyyMMdd",
						paramToFormatter: "yyyyMMdd",
						fromFormatter: "YYYY-MM",
						toFormatter: "YYYY-MM",
						fromDate: new Date(now.getFullYear(), now.getMonth(), 1),
						toDate: new Date(now.getFullYear(), now.getMonth(), now.lastDay()),
						compFromDate: "",
						compToDate: "",
					},
				},

				// Type2 조회 조건 (일간,월간,연간)
				searchBoxCondType2: {
					viewType: "usagePredict",
					conditions: {
						timeDsvn: "Month",
						compareType: "none",
					},
					datePicker: {
						viewMode: "days",
						paramFromFormatter: "yyyyMMdd",
						paramToFormatter: "yyyyMMdd",
						fromFormatter: "YYYY-MM",
						toFormatter: "YYYY-MM",
						fromDate: new Date(now.getFullYear(), now.getMonth(), 1),
						toDate: new Date(now.getFullYear(), now.getMonth(), now.lastDay()),
						compFromDate: "",
						compToDate: "",
					},
				},
			};
		},
	};
</script>

<style></style>
